.maintenance {
  width: 100%;
  background-color: aqua;
  z-index: 9998;
}

.maintenance-banner-content {
  width: auto;
  padding: 10px 10px 10px 10px;
  background-color: #f2ff00;
  text-align: center;
  z-index: 9999;
}

.maintenance-text-color {
  color: #000;
}