.App {

}

body {
    
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

.overley {
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-color: cadetblue;
    border-radius: 25px;
    /* position: fixed; */
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.btn-hight-40 {
    height: 40px;
}