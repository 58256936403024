.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: Arial, sans-serif;
  font-weight: 600;
}

body {
  
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

header {
  background-color: #333;
  color: #fff;
  padding: 10px;
}

h1 {
  margin: 0;
  font-size: 20px;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.button {
  width: 8em;
  height: 8em;
  margin: 10px;
  padding: 10px;
  text-align: center;
  border-radius: 25px;
}

.button-font-size {
  font-size: 20px;
}

.button:hover {
  background-color: #ddd;
}

.button h2 {
  margin: 0;
}