.fundo {
    background-color: #CCC;
    
}

.color-red {
    color: red;
}

.cestaItems {
    position: relative;
    overflow:visible;
    z-index: 999;
    height: 100%;
    width: 100%;
}

.cestaContainer {
    position: relative;
    align-self: right;
    /* background-color: #000; */
    z-index: 99;
    padding: 5px 5px 5px 5px;
    width: 100%;
}
