body {
    font-family: sans-serif;
  }
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
  }
  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
  }
  .suggestions li {
    padding: 0.5rem;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }
  .suggestion-active,
  .suggestions li:hover {
    /* background-color: #000000; */
    color: #000000;
    cursor: pointer;
    font-weight: 700;
  }
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }