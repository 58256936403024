

.box {
    /* border: solid 1px #999; */
    /* margin: 2px 2px 2px 2px;
    width: 120px; */

    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Raleway', sans-serif
    
}

.box:before {
    opacity: 0;
    filter: blur(5px);
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    background-size: 400%;
    transition: opacity .3 ease-in-out;
    animation: animate .2s linear infinite
}

.box:hover:before {
    opacity: 1
}

.box:hover:active {
    /* background: #000; */
    color: #fff;

    opacity: 0;
    filter: blur(5px);
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    background-size: 400%;
    transition: opacity .50s ease-in-out;
    animation: animate 1s linear infinite
}


.box:hover:active:before {
    filter: blur(2px)
}

@keyframes animate {
    0% {
        background-position: 0 0
    }

    50% {
        background-position: 400% 0
    }

    100% {
        background-position: 0 0
    }
}


.numberOfItems {
    position: absolute;
    margin-left: -25px;
    background-color: #000;
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.50);
    border: 4px groove rgba(0,0,0,0.81);
    border-radius: 40px 40px 40px 40px;
    color: #fff;
    font-size: 0.8rem;
}

.grayScale {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}
  
  /* Disable grayscale on hover */
.nonGrayScale {
    -webkit-filter: grayscale(0);
    filter: none;
}